import React from 'react'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { TableCell } from '@material-ui/core'
import renderBloks from '@renderBloks'
const useStyles = makeStyles((theme) => ({
  tableRowRoot: {},
  cell: ({ backgroundColor, variant }) => ({
    backgroundColor: backgroundColor?.color,
    border: '1px solid #C4C4C4',
    fontWeight:
      variant === 'subSectHeading' || variant === 'sectHeading' ? '700' : '300',
    textIndent: variant === 'subSectHeading' ? '20px' : '0',
    '& ul': {
      fontSize: '16px',
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: '24px !important',
      margin: '0',
      padding: '0 0 0 8px',
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    },
  }),
  image: {
    maxHeight: '80px',
    maxWidth: '16vw',
  },
}))

const TableMatrixCell = ({ blok, idx, component, scope }) => {
  const { backgroundColor, image, input, variant, button } = blok
  const classes = useStyles({ backgroundColor, variant })

  return (
    <SbEditable content={blok}>
      <TableCell
        className={classes.cell}
        align={idx === 0 ? 'left' : 'center'}
        key={blok._uid}
        component={component}
        scope={scope}
      >
        {image.filename && (
          <img className={classes.image} src={image.filename} alt={image.alt} />
        )}
        {input}

        {renderBloks(button)}
      </TableCell>
    </SbEditable>
  )
}

export default TableMatrixCell
